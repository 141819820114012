import { useState } from 'react';
import { Badge, IconButton } from '@mui/material';
import Iconify from '../../../components/iconify';
import NewsDialog from '../News';

export default function NewsIcon() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={0} color="error">
          <Iconify icon="mingcute:news-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      {open && <NewsDialog open={open} setOpen={setOpen} />}
    </>
  );
}
