import { snakeCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
// config
import { DEFAULT_LANG } from '../config-global';
// redux
import { selectCurrentUser } from '../redux/slices/auth';
import { useSelector } from '../redux/store';
import { Lang, usePatchUserLangMutation } from '../redux/coreApi';
//
import { allLangs } from './config-lang';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const user = useSelector(selectCurrentUser);

  const langStorage = localStorage.getItem('i18nextLng') || DEFAULT_LANG;

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || allLangs[0];

  const [updateLang] = usePatchUserLangMutation();

  const handleChangeLanguage = (newlang: string) => {
    if (user?.id) {
      updateLang({ body: { lang: newlang as Lang } });
    }
    i18n.changeLanguage(newlang);
  };

  useEffect(() => {
    i18n.changeLanguage(langStorage);
  }, [langStorage, i18n]);

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => `${translate(snakeCase(text), options)}`,
    currentLang,
    allLangs,
  };
}
