import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  Typography,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import get from 'lodash/get';
import { useLocales } from 'src/locales';
import { News, useGetUserQuery } from 'src/redux/coreApi';
import Iconify from 'src/components/iconify';
import useResponsive from 'src/hooks/useResponsive';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    overflowX: 'hidden',
  },
  '& .MuiPaper-root': {
    width: '712px',
    maxWidth: 'unset',
    border: 0,
    boxShadow: 'unset',
    '@media(max-width: 768px)': {
      width: 'auto',
    },
  },
}));

interface Props {
  open?: boolean;
  setOpen?: (value: boolean) => void;
}

export default function NewsDialog({ open, setOpen }: Readonly<Props>) {
  const { translate } = useLocales();
  const [news, setNews] = useState<News[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [cookies, setCookie] = useCookies(['user-news-viewed']);
  const { data: userInfo } = useGetUserQuery();

  useEffect(() => {
    if (userInfo) {
      const { news, onboardingStep, id } = userInfo;
      const userIdsViewedNews: Record<string, string> = cookies['user-news-viewed'] || {};
      const userNewsIdViewed: string[] = get(userIdsViewedNews, id as string, '').split(',');
      if (news?.length && onboardingStep === 'finish') {
        setNews(news);
        if (userNewsIdViewed.length) {
          const unreadNews = news.filter(
            (newItem) => userNewsIdViewed.indexOf(newItem.id as string) === -1
          );
          if (unreadNews.length) {
            setOpenDialog(true);
          }
        } else {
          setOpenDialog(true);
        }
        setCookie(
          'user-news-viewed',
          JSON.stringify({
            ...userIdsViewedNews,
            [id as string]: news.map((newItem: News) => newItem.id).join(','),
          }),
          {
            path: '/',
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, news]);

  const handleClose = () => {
    setOpenDialog(false);
    setOpen && setOpen(false);
  };

  const upMd = useResponsive('up', 'md');

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open || openDialog}
      maxWidth={upMd ? 'md' : 'sm'}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
          <Typography variant="h4" sx={{ fontWeight: 700, textAlign: 'center', width: '100%' }}>
            {translate('news_on_tribz')}
          </Typography>
        </Stack>
        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
          <Iconify icon="carbon:close" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" p={2}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 700, textAlign: 'center', fontSize: '16px', textWrap: 'wrap' }}
          >
            {translate('we_would_like_to_share_our_latest_information')}
          </Typography>
          <List>
            {news
              .filter((item) => item.category === 'update' && item.status === 'published')
              .map((item) => (
                <Box key={item.id}>
                  <ListItem sx={{ pb: 0 }}>
                    <ListItemText primary={item.title} />
                  </ListItem>
                  {(item as any)?.description && (
                    <List sx={{ ml: 1, p: 0 }}>
                      <ListItem>
                        <ListItemIcon sx={{ mr: 1 }}>
                          <Iconify icon="bi:dot" />
                        </ListItemIcon>
                        <ListItemText primary={(item as any).description} />
                      </ListItem>
                    </List>
                  )}
                </Box>
              ))}
          </List>

          <Stack
            direction="row"
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Box
              component="span"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 600,
                color: 'error.main',
              }}
            >
              {translate('alert')} :
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {news
                .filter((item) => item.category === 'alert' && item.status === 'published')
                .map((item) => (
                  <Box key={item.id}>
                    <Box sx={{ textTransform: 'uppercase', fontWeight: 600 }}>{item.title}</Box>
                    <Box>{item.description}</Box>
                  </Box>
                ))}
            </Box>
          </Stack>
        </Stack>

        <DialogActions>
          <Button
            variant="contained"
            sx={{ width: 'fit-content', mx: 'auto', backgroundColor: 'success.main' }}
            onClick={handleClose}
          >
            {translate('start_a_collaboration')}
          </Button>
        </DialogActions>
      </DialogContent>
    </CustomDialog>
  );
}
