// @mui
import { Theme } from '@mui/material/styles';
import { Box, SxProps } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function InstagramStoriesIcon({ sx }: Props) {
  return (
    <Box
      component="img"
      src="/assets/icons/socials/ic_instagram_stories.svg"
      sx={{ width: 24, height: 24, flexShrink: 0, ...sx }}
    />
  );
}
