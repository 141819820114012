import { createSlice } from '@reduxjs/toolkit';
import { coreApi } from '../coreApi';
import { AuthState } from '../../@types/auth';
import { isValidToken } from '../../utils/openApi';
import { RootState } from '../store';

const storeUserData = ({
  token = '',
  refreshToken = '',
  user = {},
}: {
  token?: string;
  refreshToken?: string;
  user?: any;
}) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('userId', user.id);
};

const handleLogin = (state: any, action: any) => {
  const { payload } = action;

  if (payload.user?.role && payload.user.role !== 'influencer') {
    return handleLogout(state);
  }

  storeUserData(payload);

  state.token = payload.token || '';
  state.user = {
    id: payload.user?.id || '',
    role: payload.user?.role,
    status: payload.user?.status,
  };
};

const handleLogout = (state: any) => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userId');

  state.token = '';
  state.user = null;
};

const slice = createSlice({
  name: 'auth',
  initialState: () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (token && isValidToken(token) && userId) {
      return {
        token,
        user: {
          id: userId,
        },
      } as AuthState;
    } else {
      return { token: null, user: undefined } as AuthState;
    }
  },
  reducers: {
    logout(state) {
      handleLogout(state);
    },
    tokenReceived(state: any, action) {
      const { payload } = action;
      localStorage.setItem('token', payload.token || '');
      localStorage.setItem('refreshToken', payload.refreshToken || '');

      state.token = payload.token;
    },
    ghostLogin(state, action) {
      const { payload } = action;
      localStorage.removeItem('refreshToken');

      localStorage.setItem('userId', payload.userId || '');
      localStorage.setItem('token', payload.token || '');

      state.token = payload.token;
      state.user = { id: payload.userId };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(coreApi.endpoints.login.matchFulfilled, handleLogin);

    builder.addMatcher(coreApi.endpoints.facebookLogin.matchFulfilled, handleLogin);

    builder.addMatcher(coreApi.endpoints.googleLogin.matchFulfilled, handleLogin);

    builder.addMatcher(coreApi.endpoints.postUserRegistration.matchFulfilled, (state, action) => {
      storeUserData(action.payload);

      state.token = action.payload.token ?? '';
      state.user = action.payload.user ?? {};
    });

    builder.addMatcher(coreApi.endpoints.getUser.matchFulfilled, (state, action) => {
      const { payload } = action;

      if (payload.role && payload.role !== 'influencer') {
        return handleLogout(state);
      }

      if (state.user && state.user?.id === payload.id) {
        const user = { ...payload, displayName: `${payload.firstName} ${payload.lastName}` };

        state.user = user;

        const currentLang = localStorage.getItem('i18nextLng');
        if (payload.lang && payload.lang !== currentLang) {
          localStorage.setItem('i18nextLng', payload.lang);
        }
      }
    });
  },
});

export default slice.reducer;
export const { logout, tokenReceived, ghostLogin } = slice.actions;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentAuth = (state: RootState) => state.auth;
