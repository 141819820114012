// @mui
import { enUS, frFR, zhCN, itIT, deDE, esES } from '@mui/material/locale';
//
import { getFlagIcon } from '../utils/getIcon';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: getFlagIcon('gb'),
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: getFlagIcon('fr'),
  },
  {
    label: 'Chinese',
    value: 'cn',
    systemValue: zhCN,
    icon: getFlagIcon('cn'),
  },
  {
    label: 'Italian',
    value: 'it',
    systemValue: itIT,
    icon: getFlagIcon('it'),
  },
  {
    label: 'German',
    value: 'de',
    systemValue: deDE,
    icon: getFlagIcon('de'),
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: esES,
    icon: getFlagIcon('es'),
  },
];
