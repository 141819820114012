// utils
import { userImage } from '../../utils/images';
// redux
import { useSelector } from '../../redux/store';
import { selectCurrentUser } from '../../redux/slices/auth';
//
import CustomAvatar from './CustomAvatar';
import { CustomAvatarProps } from './types';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: CustomAvatarProps) {
  const user = useSelector(selectCurrentUser) || {};

  const { displayName = '', avatarUrl = '' } = user;

  return (
    <CustomAvatar src={userImage(avatarUrl)} alt={displayName} name={displayName} {...other}>
      {displayName}
    </CustomAvatar>
  );
}
