import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
// layouts
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
//
import {
  Page500,
  FaqPage,
  ChatPage,
  LoginPage,
  GhostLogin,
  OrdersPage,
  RegisterPage,
  PageNotFound,
  VerifyCodePage,
  ValidationPage,
  NewPasswordPage,
  CampaignShopPage,
  ResetPasswordPage,
  SocialNetworksPage,
  CampaignDetailsPage,
  AccountSettingsPage,
  SetCardPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <CampaignShopPage />, index: true },
        { path: 'social-networks', element: <SocialNetworksPage /> },
        { path: 'orders', element: <OrdersPage /> },
        { path: 'my-account', element: <AccountSettingsPage /> },
        {
          path: 'shop',
          children: [
            { element: <CampaignDetailsPage />, index: true },
            { path: ':id', element: <CampaignDetailsPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'faq', element: <FaqPage /> },
      ],
    },

    // ----------------------------------------------------------------------
    {
      element: <SimpleLayout />,
      children: [
        {
          path: 'account-validation',
          element: (
            <AuthGuard>
              <ValidationPage />
            </AuthGuard>
          ),
        },
        {
          path: 'account-validation/:step',
          element: (
            <AuthGuard>
              <ValidationPage />
            </AuthGuard>
          ),
        },
        {
          path: 'auth',
          children: [
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID ?? ''}>
                    <LoginPage />
                  </GoogleOAuthProvider>
                </GuestGuard>
              ),
            },
            {
              path: 'register',
              element: (
                <GuestGuard>
                  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID ?? ''}>
                    <RegisterPage />
                  </GoogleOAuthProvider>
                </GuestGuard>
              ),
            },
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
            { path: 'ghost-login', element: <GhostLogin /> },
          ],
        },
      ],
    },

    // ----------------------------------------------------------------------
    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <PageNotFound /> },
      ],
    },
    {
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: 'setcard',
          element: <SetCardPage />,
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
