import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// AUTHENTICATION
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const GhostLogin = Loadable(lazy(() => import('../pages/auth/GhostLogin')));

// DASHBOARD
export const ValidationPage = Loadable(lazy(() => import('../pages/ValidationPage')));
export const CampaignShopPage = Loadable(lazy(() => import('../pages/CampaignShopPage')));
export const CampaignDetailsPage = Loadable(lazy(() => import('../pages/CampaignDetailsPage')));
export const AccountSettingsPage = Loadable(lazy(() => import('../pages/AccountSettingsPage')));
export const SocialNetworksPage = Loadable(lazy(() => import('../pages/SocialNetworksPage')));
export const ChatPage = Loadable(lazy(() => import('../pages/ChatPage')));
export const OrdersPage = Loadable(lazy(() => import('../pages/OrdersPage')));
export const FaqPage = Loadable(lazy(() => import('../pages/FaqPage')))

// ERRORS
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const PageNotFound = Loadable(lazy(() => import('../pages/Page404')));

//
export const SetCardPage = Loadable(lazy(() => import('../pages/SetCardPage')));
