import { useState, useEffect } from 'react';

import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// contexts
import { useChatContext } from '../../contexts/ChatContext';
// config
import { HEADER } from '../../config-global';
// redux
import { useSelector } from '../../redux/store';
import { selectCurrentAuth } from '../../redux/slices/auth';
import { useGetUserQuery } from '../../redux/coreApi';
// components
import { useSettingsContext } from '../../components/settings';
import ExpiryAlert from '../../components/tribz-expiry-alert';
import CallbackMessage from '../../components/tribz-callback-message';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import Onboarding from './Onboarding';
import NewsDialog from './News';
// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const pusher = useChatContext();
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const { user } = useSelector(selectCurrentAuth);

  const userId = `${user?.id}`;

  const { data: userInfo } = useGetUserQuery();

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';

  CallbackMessage();

  useEffect(() => {
    if (pusher) {
      pusher.subscribe(`private-user-${userId}`);

      return () => {
        pusher.unsubscribe(`private-user-${userId}`);
      };
    }
  }, [userId, pusher]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <ExpiryAlert
            socialNetworkLinks={userInfo?.socialNetworkLinks ?? []}
            userId={userId}
            sx={{
              mt: { lg: -6 },
              top: {
                lg: `${HEADER.H_DASHBOARD_DESKTOP_OFFSET * 2}px`,
              },
            }}
          />
          <Outlet />
          <Onboarding setOpen={setOpen} />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <ExpiryAlert socialNetworkLinks={userInfo?.socialNetworkLinks ?? []} userId={userId} />
            <Outlet />
            <Onboarding setOpen={setOpen} />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <ExpiryAlert socialNetworkLinks={userInfo?.socialNetworkLinks ?? []} userId={userId} />
          <Outlet />
          <Onboarding setOpen={setOpen} />
          <NewsDialog />
        </Main>
      </Box>
    </>
  );
}
