// components
import Image from '../components/image';
// assets
import {
  BlogIcon,
  TiktokIcon,
  YoutubeIcon,
  InstagramIcon,
  InstagramReelsIcon,
  InstagramStoriesIcon,
  YoutubeShortsIcon,
} from '../assets/icons';

// redux
import { MediaTypeForOrder } from '../redux/coreApi';

// ----------------------------------------------------------------------

export function getIconFile(name: string) {
  return <Image src={`/icons/files/${name}.svg`} alt={name} sx={{ width: 28, height: 28 }} />;
}

export function getFlagIcon(code: string) {
  return `https://flagcdn.com/${code.toLowerCase()}.svg`;
}

export function getSocialIcon(social?: MediaTypeForOrder | string) {
  let icon;

  switch (social) {
    case 'instagram':
    case 'instagramPost':
    case 'instagramVideo':
    case 'instagramCarousel':
      icon = <InstagramIcon />;
      break;
    case 'instagramReel':
      icon = <InstagramReelsIcon />;
      break;
    case 'instagramStory':
      icon = <InstagramStoriesIcon />;
      break;
    case 'tiktok':
    case 'tiktokVideo':
      icon = <TiktokIcon />;
      break;
    case 'blog':
      icon = <BlogIcon />;
      break;
    case 'youtubeShorts':
      icon = <YoutubeShortsIcon />;
      break;
    default:
      icon = <YoutubeIcon />;
  }

  return icon;
}
