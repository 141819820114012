const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  return true;
};

const setSession = (accessToken: string | null, userId: string | null = null) => {
  if (accessToken && userId) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('userId', userId);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
  }
};

export { isValidToken, setSession };
