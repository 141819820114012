// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  myAccount: '/my-account',
  accountValidation: '/account-validation',
  orders: '/orders',
  socialNetworks: '/social-networks',
  chat: {
    root: '/chat',
    new: '/chat/new',
    conversation: '/chat/:conversationKey',
    view: (key: string) => `/chat/${key}`,
  },
  shop: {
    view: (id: string) => `/shop/${id}`,
  },
  faq: '/faq',
};
