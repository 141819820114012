// @mui
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material';
//
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function YoutubeIcon({ sx }: Props) {
  return <Iconify icon="logos:youtube-icon" sx={{ width: 24, height: 24, flexShrink: 0, ...sx }} />;
}
