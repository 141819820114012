/* eslint-disable import/no-duplicates */
import fr from 'date-fns/locale/fr';
import zhCN from 'date-fns/locale/zh-CN';
import {
  format,
  getTime,
  differenceInDays,
  formatDistanceToNow,
  formatDistanceToNowStrict,
} from 'date-fns';

// ----------------------------------------------------------------------

export function fDifferenceDays(endDate: Date | number, startDate: Date | number) {
  return differenceInDays(endDate, startDate);
}

export function fHours(date: Date | string | number) {
  return format(new Date(date), 'hh');
}

export function fMinutes(date: Date | string | number) {
  return format(new Date(date), 'mm');
}

export function fTime(date: Date | string | number) {
  return `${fHours(date)}h${fMinutes(date)}`;
}

export function fDate(date: Date | string | number, template = 'dd/MM/yyyy') {
  return format(new Date(date), template);
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number, locale?: string) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: (locale === 'fr' && fr) || (locale === 'cn' && zhCN) || undefined,
  });
}

export function fToNowStrict(date: Date | string | number, locale?: string) {
  return formatDistanceToNowStrict(new Date(date), {
    addSuffix: true,
    locale: (locale === 'fr' && fr) || (locale === 'cn' && zhCN) || undefined,
  });
}
