import { useState, useEffect, useRef, useCallback } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// hooks
import { useLocales } from '../../../locales';
import { useNotificationContext } from '../../../contexts/NotificationContext';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
// redux
import { useSelector } from '../../../redux/store';
import { selectCurrentAuth } from '../../../redux/slices/auth';
import {
  useGetUserNotificationsQuery,
  usePutUserNotificationsReadMutation,
  useGetUserQuery,
} from '../../../redux/coreApi';
// ----------------------------------------------------------------------

type NotificationItemProps = {
  id?: string;
  title?: string;
  description?: string;
  avatar?: string;
  type?: string;
  createdAt?: string;
  readAt?: string;
};

export default function NotificationsPopover() {
  const { translate } = useLocales();

  const { user } = useSelector(selectCurrentAuth);
  const scrollRef = useRef<HTMLDivElement>(null);

  const { data = {}, isLoading, refetch } = useGetUserQuery();
  const [notifications, setNotifications] = useState<NotificationItemProps[]>([]);
  const [cursor, setCursor] = useState<string | undefined>();
  const [lastItemId, setLastItemId] = useState<string | undefined>();
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const [readNotifications] = usePutUserNotificationsReadMutation();

  const [totalUnRead, setTotalUnread] = useState(0);

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const [pusherChannel, setPusherChannel] = useState<any>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    readNotifications({
      body: {
        notificationIds: notifications.filter(({ readAt }) => !readAt).map(({ id = '' }) => id),
      },
    });
    setNotifications(
      notifications.map((n) => (!n.readAt ? { ...n, readAt: new Date().toString() } : n))
    );

    refetch();
  };

  const pusher = useNotificationContext();

  useEffect(() => {
    if (pusher && user?.id) {
      const channel = pusher.subscribe(`private-notifications-${user.id}`);

      setPusherChannel(channel);

      return () => {
        pusher.unsubscribe(`private-notifications-${user.id}`);
      };
    }
  }, [pusher, user]);

  useEffect(() => {
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind('new_notification');
      pusherChannel.bind('new_notification', (data: any) => {
        setNotifications((prev) => [data, ...prev]);
        refetch();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pusherChannel]);

  const handleScroll = useCallback(() => {
    if (
      scrollRef.current &&
      scrollRef.current?.scrollTop + scrollRef.current?.clientHeight >
        scrollRef.current?.scrollHeight - 50
    ) {
      if (lastItemId) {
        setCursor(lastItemId);
      }
    }
  }, [lastItemId]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.removeEventListener('scroll', handleScroll);
      scrollRef.current.addEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (!isLoading) {
      setTotalUnread(data.unreadNotifications || 0);
    }
  }, [isLoading, data]);

  return (
    <>
      <IconButton
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notifications')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('notification_total_unread', { totalUnRead })}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title={translate('mark_all_as_read')}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box
          sx={{
            height: 90 * 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!notifications.length && firstLoad ? (
            <Typography variant="subtitle1">No notifications</Typography>
          ) : (
            <Scrollbar initial scrollableNodeProps={{ ref: scrollRef }}>
              <NotificationItems
                notifications={notifications}
                setNotifications={setNotifications}
                cursor={cursor}
                setLastItemId={setLastItemId}
                firstLoad={firstLoad}
                setFirstLoad={setFirstLoad}
              />
            </Scrollbar>
          )}
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------
function NotificationItems({
  notifications,
  setNotifications,
  cursor,
  setLastItemId,
  firstLoad,
  setFirstLoad,
}: {
  notifications: NotificationItemProps[];
  setNotifications: (items: NotificationItemProps[]) => void;
  setLastItemId: (id?: string) => void;
  firstLoad: boolean;
  setFirstLoad: (loaded: boolean) => void;
  cursor?: string;
}) {
  const { data = [], isSuccess } = useGetUserNotificationsQuery({ limit: '5', cursor });

  useEffect(() => {
    if (isSuccess) {
      const newItems = data.filter((item) => !notifications.find((noti) => noti.id === item.id));
      setNotifications([...notifications, ...newItems]);

      if (newItems.length > 0) {
        setLastItemId(newItems[newItems.length - 1]?.id);
      } else {
        setLastItemId(undefined);
      }

      if (!firstLoad) {
        setFirstLoad(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  return (
    <List disablePadding>
      {notifications.map((notification, index) => (
        <NotificationItem key={`${notification.id}-${index}`} notification={notification} />
      ))}
    </List>
  );
}
// ----------------------------------------------------------------------

function NotificationItem({ notification }: { notification: NotificationItemProps }) {
  const {
    currentLang: { value: lang },
  } = useLocales();
  const { readAt, createdAt } = notification;

  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!readAt && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {createdAt ? fToNow(createdAt, lang) : fToNow(new Date(), lang)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationItemProps) {
  const {
    title: notificationTitle = '',
    description = '',
    type = '',
    avatar = null,
  } = notification;

  const title = (
    <Typography variant="subtitle2">
      {notificationTitle}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {description}
      </Typography>
    </Typography>
  );

  if (type === 'order_placed') {
    return {
      avatar: <img alt={notificationTitle} src="/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (type === 'order_shipped') {
    return {
      avatar: <img alt={notificationTitle} src="/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (type === 'mail') {
    return {
      avatar: <img alt={notificationTitle} src="/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (type === 'chat_message') {
    return {
      avatar: <img alt={notificationTitle} src="/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: avatar ? <img alt={notificationTitle} src={avatar} /> : null,
    title,
  };
}
