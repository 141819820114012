import Pusher from 'pusher-js';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { createContext, useContext } from 'react';
// redux
import { useSelector } from '../redux/store';
import { selectCurrentAuth } from '../redux/slices/auth';
// ----------------------------------------------------------------------

const initialState = new Pusher(`${process.env.REACT_APP_KEY}`, {
  cluster: `${process.env.REACT_APP_CLUSTER}`,
});

export const NotificationContext = createContext(initialState);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  if (!context) throw new Error('useNotificationContext must be use inside SettingsProvider');

  return context;
};

// ----------------------------------------------------------------------

export const NotificationProvider = ({ children }: { children: any }) => {
  const { user, token } = useSelector(selectCurrentAuth);

  const pusher = new Pusher(`${process.env.REACT_APP_KEY}`, {
    cluster: `${process.env.REACT_APP_CLUSTER}`,
    authEndpoint: `${process.env.REACT_APP_API_URL}/user/notifications/auth`,
    auth: {
      params: {
        userId: user?.id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  try {
    if (process.env.REACT_APP_PUSH_INSTANCE_ID) {
      window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        // Chrome
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: `${process.env.REACT_APP_PUSH_INSTANCE_ID}`,
          serviceWorkerRegistration,
        });

        const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
          url: `${process.env.REACT_APP_API_URL}/user/notifications/pusher/beams-auth`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        beamsClient
          .start()
          .then(() => {
            beamsClient.setUserId(user?.id || '', beamsTokenProvider);
          })
          .catch(console.error);
      });
    }
  } catch (error) {
    console.error(error);
  }

  return <NotificationContext.Provider value={pusher}>{children}</NotificationContext.Provider>;
};
