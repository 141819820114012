// @mui
import { styled, alpha } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
// utils
import { fShortenNumber, fPercent } from '../../../utils/formatNumber';
// redux
import { useGetUserQuery } from '../../../redux/coreApi';
// components
import Iconify from '../../../components/iconify';
import { MyAvatar } from '../../../components/custom-avatar';
// assets
import { InstagramIcon, TiktokIcon, YoutubeIcon } from '../../../assets/icons';

// ----------------------------------------------------------------------

const iconStyle = {
  width: 16,
  height: 16,
  mr: 0.5,
};

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

const StyledStack = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  lineHeight: 0,
  alignItems: 'center',
  display: 'inline-flex',
}));

// ----------------------------------------------------------------------

export default function NavAccount() {

  const { data: userInfo = {} } = useGetUserQuery();

  const { socialNetworksStatisticsByMedia: statistics = {} } = userInfo;

  const { instagram, youtube, tiktok } = statistics;

  return (
    <RootStyle>
      <MyAvatar sx={{ mb: 2 }} />

      <>
        <Typography variant="subtitle1" noWrap>
          {userInfo?.firstName} {userInfo?.lastName}
        </Typography>

        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {userInfo?.email}
        </Typography>

        {instagram && (
          <Stack direction="row" spacing={2} sx={{ color: 'text.secondary', mt: 2 }}>
            <StyledStack>
              <InstagramIcon sx={{ ...iconStyle }} />
              {fShortenNumber(Number(instagram.followers || 0))}
            </StyledStack>

            <StyledStack>
              <Iconify icon="eva:flash-fill" sx={{ ...iconStyle, color: 'warning.main' }} />
              {fPercent(Number(instagram.engagementRate || 0))}
            </StyledStack>
          </Stack>
        )}

        {youtube && (
          <Stack direction="row" spacing={2} sx={{ color: 'text.secondary', mt: 1 }}>
            <StyledStack>
              <YoutubeIcon sx={{ ...iconStyle }} />
              {fShortenNumber(Number(youtube.followers || 0))}
            </StyledStack>

            <StyledStack>
              <Iconify icon="eva:flash-fill" sx={{ ...iconStyle, color: 'warning.main' }} />
              {fPercent(Number(youtube.engagementRate || 0))}
            </StyledStack>
          </Stack>
        )}

        {tiktok && (
          <Stack direction="row" spacing={2} sx={{ color: 'text.secondary', mt: 1 }}>
            <StyledStack>
              <TiktokIcon sx={{ ...iconStyle }} />
              {fShortenNumber(Number(tiktok.followers || 0))}
            </StyledStack>

            <StyledStack>
              <Iconify icon="eva:flash-fill" sx={{ ...iconStyle, color: 'warning.main' }} />
              {fPercent(Number(tiktok.engagementRate || 0))}
            </StyledStack>
          </Stack>
        )}
      </>
    </RootStyle>
  );
}
