import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router';
import { useLocales } from '../../locales';

export default function CallbackMessage() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { search } = useLocation();
  const connectmsg = new URLSearchParams(search).get('cmsg');
  const { translate } = useLocales();

  // Test with Notifications
  const action = (snackbarId: any) => (
    <Button onClick={() => closeSnackbar(snackbarId)}>Dismiss</Button>
  );

  if (connectmsg === 'uc') {
    enqueueSnackbar(translate('notification_ig_connect_usercancel'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'nact') {
    enqueueSnackbar(translate('notification_ig_connect_no_account_selected'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'wact') {
    enqueueSnackbar(translate('notification_ig_connect_wrong_type_account_selected'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'mscp') {
    enqueueSnackbar(translate('notification_ig_connect_missing_scopes'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'mact') {
    enqueueSnackbar(translate('notification_ig_connect_multiple_accounts_connected'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'alcon') {
    enqueueSnackbar(translate('notification_ig_connect_accounts_already_connected'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'igs') {
    enqueueSnackbar(translate('notification_ig_connect_successful'), {
      variant: 'success',
    });
  }
  if (connectmsg === 'ytuc') {
    enqueueSnackbar(translate('notification_yt_connect_usercancel'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'ytmscp') {
    enqueueSnackbar(translate('notification_yt_connect_missing_scopes'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'ytalcon') {
    enqueueSnackbar(translate('notification_yt_account_already_connected'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'yts') {
    enqueueSnackbar(translate('notification_yt_connect_successful'), {
      variant: 'success',
    });
  }
  if (connectmsg === 'ttuc') {
    enqueueSnackbar(translate('notification_tt_connect_usercancel'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'ttmscp') {
    enqueueSnackbar(translate('notification_tt_connect_missing_scopes'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'ttalcon') {
    enqueueSnackbar(translate('notification_tt_account_already_connected'), {
      variant: 'error',
      persist: true,
      action,
    });
  }
  if (connectmsg === 'tts') {
    enqueueSnackbar(translate('notification_tt_connect_successful'), {
      variant: 'success',
    });
  }
  return null;
}
