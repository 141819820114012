import { createContext, useContext, useMemo } from 'react';
import { useSetState } from 'react-use';
import { Step } from 'react-joyride';

export interface OnboardingState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}

const onboardingState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const OnboardingContext = createContext({
  state: onboardingState,
  setState: () => undefined,
});
OnboardingContext.displayName = 'OnboardingContext';

export function OnboardingProvider(props: any) {
  const [state, setState] = useSetState(onboardingState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state]
  );

  return <OnboardingContext.Provider value={value} {...props} />;
}

export function useOnboardingContext(): {
  setState: (
    patch: Partial<OnboardingState> | ((previousState: OnboardingState) => Partial<OnboardingState>)
  ) => void;
  state: OnboardingState;
} {
  const context = useContext(OnboardingContext);

  if (!context) {
    throw new Error('useOnboardingContext must be used within a OnBoardingProvider');
  }

  return context;
}
