import numeral from 'numeral';

// numeral.register('locale', 'fr', {
//   delimiters: {
//     thousands: ' ',
//     decimal: ',',
//   },
//   abbreviations: {
//     thousand: 'K',
//     million: 'M',
//     billion: 'B',
//     trillion: 'T',
//   },
//   ordinal: function (number) {
//     return number === 1 ? 'er' : 'ème';
//   },
//   currency: {
//     symbol: '$',
//   },
// });

import 'numeral/locales/fr';

numeral.locale('fr');

// ----------------------------------------------------------------------

export function fCurrency(number: number) {
  // return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number: number) {
  return numeral(number / 100)
    .format('0.0%')
    .replace(',0', '');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number | undefined) {
  if (number){
    number = numeral(number).format('0,0');
  }
  if (number && number <= 9999) {
    return String(number);
  } else if (number && number <= 999999 && number > 100000) {
    return numeral(number).format('0a').replace(',0', '');
  } else {
    return numeral(number).format('0.0a').replace(',0', '');
  }
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}
