// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  campaigns: icon('ic_cart'),
  chat: icon('ic_chat'),
  analytics: icon('ic_analytics'),
  ecommerce: icon('ic_ecommerce'),
  faq: icon('ic_faqMagnifier')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'shop',
        path: PATH_DASHBOARD.root,
        icon: ICONS.campaigns,
      },
      {
        title: 'my_orders',
        path: PATH_DASHBOARD.orders,
        icon: ICONS.ecommerce,
      },
      {
        title: 'social_networks',
        path: PATH_DASHBOARD.socialNetworks,
        icon: ICONS.analytics,
      },
      {
        title: 'chat',
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },
      {
        title: 'faq_short',
        path: PATH_DASHBOARD.faq,
        icon: ICONS.faq,
      },
    ],
  },
];

export default navConfig;
