// @mui
import { Theme } from '@mui/material/styles';
import { Avatar, AvatarProps, Box, SxProps } from '@mui/material';
// utils
import { getSocialIcon } from '../../utils/getIcon';
// redux
import { MediaTypeForOrder } from '../../redux/coreApi';
// ----------------------------------------------------------------------

interface Props extends AvatarProps {
  format?: MediaTypeForOrder;
  sx?: SxProps<Theme>;
}

export default function SocialAvatar({ format, sx, ...other }: Props) {
  return (
    <Box sx={{ position: 'relative', height: 48, width: 48, flexShrink: 0, ...sx }}>
      <Box sx={{ position: 'absolute', top: -8, right: -8, zIndex: 9 }}>
        {getSocialIcon(format)}
      </Box>

      <Avatar
        sx={{ borderRadius: 1, width: 1, height: 1, maxWidth: 48, maxHeight: 48 }}
        {...other}
      />
    </Box>
  );
}
