import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton } from '@mui/material';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// locales
import { useLocales } from '../../../locales';
// components
import { useSnackbar } from '../../../components/snackbar';
import SettingsDrawer from '../../../components/settings/drawer';
import { MyAvatar } from '../../../components/custom-avatar';
import MenuPopover from '../../../components/menu-popover';
// redux
import { selectCurrentUser, logout } from '../../../redux/slices/auth';
import { useLogoutMutation } from '../../../redux/coreApi';
import { dispatch, useSelector } from '../../../redux/store';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'home',
    linkTo: '/',
  },
  {
    label: 'my_account',
    linkTo: PATH_DASHBOARD.myAccount,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { translate } = useLocales();

  const navigate = useNavigate();

  const user = useSelector(selectCurrentUser);

  const [doLogout] = useLogoutMutation();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await doLogout();
      dispatch(logout());
      navigate(PATH_AUTH.login, { replace: true });
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(translate('snackbar_logout_error'), { variant: 'error' });
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButton>

      <MenuPopover open={open} anchorEl={open} onClose={handleClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {translate(option.label)}
            </MenuItem>
          ))}

          <SettingsDrawer onCloseMenu={handleClose} />
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
