// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

// redux
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// contexts
import { ChatProvider } from './contexts/ChatContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { OnboardingProvider } from './contexts/OnboardingContext';
// components
import ScrollToTop from './components/scroll-to-top';
import SnackbarProvider from './components/snackbar';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <OnboardingProvider>
            <NotificationProvider>
              <ChatProvider>
                <SettingsProvider>
                  <BrowserRouter>
                    <MotionLazyContainer>
                      <ThemeProvider>
                        <ThemeSettings>
                          <SnackbarProvider>
                            <ScrollToTop />
                            <Router />
                          </SnackbarProvider>
                        </ThemeSettings>
                      </ThemeProvider>
                    </MotionLazyContainer>
                  </BrowserRouter>
                </SettingsProvider>
              </ChatProvider>
            </NotificationProvider>
          </OnboardingProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </HelmetProvider>
  );
}
