import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

// routes
import { PATH_DASHBOARD } from '../routes/paths';
// redux
import { useSelector } from '../redux/store';
import { selectCurrentUser } from '../redux/slices/auth';
// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const user = useSelector(selectCurrentUser);

  if (user) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
