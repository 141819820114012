import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import enLocales from './langs/en.json';
import frLocales from './langs/fr.json';
import cnLocales from './langs/cn.json';
import itLocales from './langs/it.json';
import deLocales from './langs/de.json';
import esLocales from './langs/es.json';
// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      fr: { translations: frLocales },
      cn: { translations: cnLocales },
      de: { translations: deLocales },
      it: { translations: itLocales },
      es: { translations: esLocales },
    },
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
