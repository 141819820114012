// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_API_URL || '';

export const GOOGLE_MAP_API = process.env.REACT_APP_GOOGLE_MAP_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root; // as '/dashboard/app'

export const termsOfServiceLink = process.env.REACT_APP_DARK_LOGO;
export const privacyPolicyLink = process.env.REACT_APP_DARK_LOGO;

// LOGO
// ----------------------------------------------------------------------

export const LOGO = {
  LIGHT: process.env.REACT_APP_LIGHT_LOGO,
  DARK: process.env.REACT_APP_DARK_LOGO,
};

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 40,
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 22,
  NAV_ITEM_HORIZONTAL: 20,
  NAV_ITEM_MINI: 22,
};

// LANG
export const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG || 'en';

// IMAGE
// ----------------------------------------------------------------------

export const CAMPAIGN_IMAGE_PATH = process.env.REACT_APP_CAMPAIGN_ASSETS || '';
export const USER_ASSETS_PATH = process.env.REACT_APP_USER_ASSETS || '';
export const USER_IG_STORY_ASSETS_PATH = process.env.REACT_APP_USER_IG_STORY_ASSETS || '';
export const CHAT_ASSETS_PATH = process.env.REACT_APP_CHAT_ASSETS;
export const MAUTIC_URL = process.env.REACT_APP_MAUTIC_URL;
