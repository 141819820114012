import Pusher from 'pusher-js';
import { createContext, useContext, useMemo } from 'react';
import { useSelector } from '../redux/store';
import { selectCurrentAuth } from '../redux/slices/auth';

// ----------------------------------------------------------------------

const initialState = new Pusher(`${process.env.REACT_APP_KEY}`, {
  cluster: `${process.env.REACT_APP_CLUSTER}`,
});

export const ChatContext = createContext(initialState);

export const useChatContext = () => {
  const context = useContext(ChatContext);

  if (!context) throw new Error('useChatContext must be use inside SettingsProvider');

  return context;
};

// ----------------------------------------------------------------------

export const ChatProvider = ({ children }: { children: any }) => {
  const { user, token } = useSelector(selectCurrentAuth);

  const pusher = useMemo(
    () =>
      new Pusher(`${process.env.REACT_APP_KEY}`, {
        cluster: `${process.env.REACT_APP_CLUSTER}`,
        authEndpoint: `${process.env.REACT_APP_API_URL}/user/chat/auth`,
        auth: {
          params: {
            userId: user?.id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      }),
    [token, user?.id]
  );
  

  return <ChatContext.Provider value={pusher}>{children}</ChatContext.Provider>;
};
