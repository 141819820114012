import { useNavigate, Outlet, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Stack } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Logo from '../../components/tribz-logo';
// hooks
import { useLocales } from '../../locales';
// redux
import { useLogoutMutation } from '../../redux/coreApi';
import { dispatch, useSelector } from '../../redux/store';
import { logout, selectCurrentUser } from '../../redux/slices/auth';
//
import LanguagePopover from '../dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  hideLogo?: boolean;
};

export default function SimpleLayout({ hideLogo }: Props) {
  const { pathname } = useLocation();

  const user = useSelector(selectCurrentUser);

  const { translate } = useLocales();

  const [doLogout] = useLogoutMutation();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await doLogout();
      dispatch(logout());
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const paths = [
    PATH_AUTH.login,
    PATH_AUTH.register,
    PATH_AUTH.newPassword,
    PATH_AUTH.resetPassword,
  ];

  const showLogo = !paths.includes(pathname);

  return (
    <>
      <HeaderStyle>
        {showLogo && <Logo />}

        <Stack direction="row" flexGrow={1} spacing={1} justifyContent="flex-end">
          <LanguagePopover />

          {!!user && (
            <Button onClick={handleLogout} variant="text" sx={{ m: 0 }} size="small">
              {translate('logout')}
            </Button>
          )}
        </Stack>
      </HeaderStyle>

      <Outlet />
    </>
  );
}
