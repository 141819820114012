import {
  BlogIcon,
  TiktokIcon,
  YoutubeIcon,
  InstagramIcon,
  YoutubeShortsIcon,
  InstagramReelsIcon,
  InstagramStoriesIcon,
} from './assets/icons';

// ----------------------------------------------------------------------

export const EXPIRED_DAY = 10;

export const GENDER_OPTIONS = ['male', 'female', 'other'];

export const VALIDATION_STEPS = [
  'mail_confirmation',
  'complete_your_profile',
  'connect_social_networks',
  'phone_validation',
];

export const ACCOUNT_NOTIFICATIONS = [
  {
    value: 'EmailMeWhenTheStatusOfMyOrderChanges',
    label: 'notification_when_my_campaign_status_changes',
  },
  {
    value: 'EmailMeWhenIGetAMessageFromTribz',
    label: 'notification_when_get_a_chat_message',
  },
  { value: 'NewsAndAnnouncements', label: 'notification_news_and_announcements' },
  { value: 'WeeklyProductUpdates', label: 'notification_weekly_product_updates' },
  { value: 'WeeklyBlogDigest', label: 'notification_weekly_blog_digest' },
];

export const AGE_LABELS = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

export const SHOP_FILTER_TYPE = [
  {
    value: 'instagram',
    label: 'instagram',
    icon: [<InstagramIcon key="instagram" />, <InstagramReelsIcon key="instagram_reels" />],
  },
  { value: 'instagramStory', label: 'instagram_story', icon: <InstagramStoriesIcon /> },
  { value: 'tiktok', label: 'tiktok', icon: <TiktokIcon /> },
  {
    value: 'youtube',
    label: 'Youtube',
    icon: [<YoutubeIcon key="youtube" />, <YoutubeShortsIcon key="youtube_shorts" />],
  },
  { value: 'blog', label: 'blog', icon: <BlogIcon /> },
];

export const SHOP_SORT_BY = [
  { value: 'newest', label: 'newest' },
  { value: 'endingSoon', label: 'ending_soon' },
  { value: 'priceDesc', label: 'price_desc' },
  { value: 'priceAsc', label: 'price_asc' },
];

export const ONBOARDING_STEPS = {
  start: 0,
  campaigncard: 1,
  campaigndetails: 2,
  campaignrequirements: 3,
  hashtagsandmentions: 4,
  ordersMobileNavMenuOpen: 5,
  orders: 6,
  uploadcontent: 7,
  statsMobileNavMenuOpen: 8,
  stats: 9,
  statsstatistics: 10,
  statsaudience: 11,
  statsgeography: 12,
  chatMobileNavMenuOpen: 13,
  chat: 14,
  chatconversation: 15,
  finish: 16,
  completed: 17,
};
