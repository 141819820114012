import { createRoot } from 'react-dom/client';
//
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// ----------------------------------------------------------------------

const rootElement = document.getElementById('root');

const root = createRoot(rootElement!);

root.render(<App />);

serviceWorkerRegistration.register();

reportWebVitals();
