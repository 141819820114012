import { useState } from 'react';
// hooks
import { useLocales } from '../../../locales';
// components
import Label from '../../../components/label';
import MenuPopover from '../../../components/menu-popover';
// redux
import { useSelector } from '../../../redux/store';
import { selectCurrentUser } from '../../../redux/slices/auth';
// ----------------------------------------------------------------------

export default function CreditsInfoLabel() {
  const user = useSelector(selectCurrentUser);
  const { credits = 0, usedCredits = 0 } = user as any;

  const { translate } = useLocales();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Label variant="outlined" color="primary" onClick={handleOpen} sx={{ px: 0.75 }}>
        {credits - usedCredits}/{credits} {translate('credits')}
      </Label>

      <MenuPopover
        open={open}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          maxWidth: 240,
          typography: 'body2',
        }}
      >
        {translate('with_each_credit')}
      </MenuPopover>
    </>
  );
}
